/**
 * About page
 */
/* Import section */
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import "../styles/pages/about-us.scss"
import { Helmet } from 'react-helmet'

/* Page function declaration */
const MedicalRecordsRequest = ({ data }) => {

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageMedicalRecordsRequest

  //CTA Footer page CONTACT
  var ctaFooterContact = data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "contact"
  })

  useEffect(() =>  {
    initializeHubspotForm();
  }, [])
  const initializeHubspotForm = () => {
      if ('hbspt' in window) {
          window.hbspt.forms.create({
          
          
          region: "na1",
    portalId: "166457",
    formId: "7ad80ae2-f13c-4cb9-99a8-e2443bc8c633",
    target: "#hubspotForm"
        });
      } else {
        setTimeout(initializeHubspotForm, 500)
     }
   }

  return (
    <Layout className="about">
      <Helmet>
      <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
      </Helmet>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Section Hero */}
      <Hero classNamePage="about" colorHero="green-light" colorShape="green" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralMedicalRecordsReport} classNameH1="about green-light" vc="true">
        <div className="content-history">
          {/* <StaticImage src="../images/logos/logo_hellohealth.png" alt="Logo Hello Health" layout="fixed" width={260} quality={100} /> */}
          <div className="box-history intro-history" dangerouslySetInnerHTML={{ __html:dataAcf.contentMedicalRecordsReport}} />
          <div className="box-history" dangerouslySetInnerHTML={{ __html:dataAcf.contentGenerelMedicalRecordsReport}} />
         
          
         
        </div>

      </Sectionh1content >

      {/* Section Values */}
      <section className="section-values">
        <div className="content-values">
        {/* <iframe src="https://share.hsforms.com/1etgK4vE8TLmZqOJEO8jGMw3kft" id="hs-form-iframe-0" class="hs-form-iframe" title="Form 0" scrolling="no" width="100%" style="position: static; border: none; display: block; overflow: hidden; width: 100%; height: auto;" height="auto"></iframe> */}
        <div id="hubspotForm" class="hubspot-form">
       
        </div>
        <div className="box-history" dangerouslySetInnerHTML={{ __html:dataAcf.contentPcAbout}} >
        </div>
        </div>
      </section>

      {/* Section CtaFooter */}
     
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "medical-records-request"}) {
      acfPageMedicalRecordsRequest {
        titleGeneralMedicalRecordsReport
        contentMedicalRecordsReport
        contentGenerelMedicalRecordsReport
      }
      seo {
        metaDesc
        title
      }
    } 
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }

  }
`
/* Export page informations */
export default MedicalRecordsRequest



